<template>
  <CRow>
    <CCol col="12" lg="10">
      <CCard>
        <CCardHeader>
          Edit Remediation Item
        </CCardHeader>
        <CCardBody>
          <p class="text-center" v-show="loading">
            <CSpinner />
          </p >   
          <span v-show="!loading">
            <CCardBody class="p-4">
              <CForm>
                <CAlert
                  :show.sync="itemSaved"
                  closeButton
                  color="success"
                >
                Remediation Item saved!
                </CAlert>                  
                <ASelect
                  label="Lense"
                  :options="lenses"
                  :value.sync='item.item_lense'
                  @change="reloadChoices($event)"
                  >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </ASelect>    

                <ASelect
                  label="Pillar"
                  :options="pillars"
                  :value.sync='item.item_pillar'
                  @change="reloadChoices($event)"
                  >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </ASelect>

                <CInput
                  label="Title"
                  placeholder="Title"
                  v-model="item.item_title"                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>

                <CSelect
                  label="Effort"
                  v-model="item.item_effort"
                  :value.sync="item.item_effort"
                  :options=effortList
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CSelect>     
                <div>
                  <p>Description</p>
                  <p>
                    <quill-editor
                      label="Description"
                      ref="myQuillEditor"
                      v-model="item.item_desc"                
                      :options="editorOption"
                    />
                    <!-- <quill-editor
                      label="Description"
                      ref="myQuillEditor"
                      v-model="item.item_desc"                
                      :options="editorOption"
                      @blur="onEditorBlur($event)"
                      @focus="onEditorFocus($event)"
                      @ready="onEditorReady($event)"
                    />                     -->
                  </p>
                </div>                   
              <template>
                <div>
                  <p>Choices</p>
                  <p>   
                  <multiselect
                    :value="selectedChoiceList"
                    :options="choices"
                    track-by="id"
                    :searchable="true"
                    :close-on-select="false"
                    :multiple="true"
                    label="name" 
                    :hideSelected="true"
                    :loading="choicesLoading"
                    @select="selectChoice"
                    @remove="removeChoice" 
                  >
                   </multiselect>
                  </p>
                </div>
              </template>        

              </CForm>
            </CCardBody> 
            </span>         
        </CCardBody>
        <CCardFooter>
          <CButton  class="float-left col-sm-2" color="light" @click="goBack">Back</CButton>
          <CButton v-on:click="saveItem();" color="success" class="float-right col-sm-4" >Save Item</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>



<script>
import ASelect from '@/components/forms/ASelect.vue';
import Multiselect from 'vue-multiselect';
import LenseService from '@/services/lense.services';
import ItemService from '@/services/item.services';
import ChoiceService from '@/services/choice.services';

export default {
  components: {
    ASelect,
    Multiselect
  },  
  data() {
    return {
      item: {},
      lenses: {},
      pillars: {},      
      choices: [],
      selectedChoiceList: [],
      selectedLenseId: "",
      effortList: [ 0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0, 2.25, 2.5, 2.75, 3.0, 3.25, 3.5, 3.75, 4.0  ],
      editorOption: {},
      itemSaved: false,   
      loading: true,
      choicesLoading: true,
      pillarsLoading: true,
      lensesLoading: true
    };
  },

  methods: {
    goBack() {
      this.$router.push({path: '/remediation-items'})
    },    
    reloadChoices() {
      this.getChoicesByLensePillar()
    },
    selectChoice(selectedChoice) {
      var tmp = {}
      tmp['id'] = selectedChoice.id
      tmp['name'] = selectedChoice.name
      this.selectedChoiceList.push(tmp)
    },
    removeChoice(removedOption, id) {
      const index = this.selectedChoiceList.indexOf(removedOption);
      if (index > -1) {
         this.selectedChoiceList.splice(index, 1);
      }
    },
    // onEditorBlur(quill) {
    //   console.log('editor blur!', quill)
    // },
    // onEditorFocus(quill) {
    //   console.log('editor focus!', quill)
    // },
    // onEditorReady(quill) {
    //   console.log('editor ready!', quill)
    // },
    // onEditorChange({ quill, html, text }) {
    //   console.log('editor change!', quill, html, text)
    //   this.items.item_desc = html
    // },
    async processChoicesData() {
    
      for (var key in this.item.item_hri){
        ChoiceService.getChoice(this.item.item_hri[key]).then(
          response => { 
            var choice = response;
            var tmp = {}
            tmp["id"] = choice.choice_id
            tmp["name"] = choice.choice_name
        
            this.selectedChoiceList.push(tmp)
          },
          error => {   
            console.log(error)   
          }           
        )

      }

      this.selectedLenseId = this.item.item_lense

      await this.getChoicesByLensePillar()

      this.loading = false;
      
    },    
    async getChoicesByLensePillar() {
      ChoiceService.getChoicesByLensePillar(this.item.item_lense, this.item.item_pillar).then(
        response => { 
          // clear choice list
          this.choicesLoading = true;
          this.choices = []
          
          for (var key in response){
            var tmp = {}
            tmp["id"] = response[key].choice_id
            tmp["name"] = response[key].choice_name
            tmp["question_id"] = response[key].choice_question_id
            tmp["question_title"] = response[key].choice_question
        
            this.choices.push(tmp)
          }
          this.choicesLoading = false;
        },
        error => {   
          console.log(error)   
        }           
      );
    },
    async saveItem() {

      var tmp_choices = []
      for (var key in this.selectedChoiceList) {
        tmp_choices.push(this.selectedChoiceList[key].id)
      }

      ItemService.saveItem(this.$route.params.id, this.item.item_desc, this.item.item_title, this.item.item_lense, tmp_choices, this.item.item_pillar, this.item.item_effort).then(
        response => { 
          this.itemSaved = true;
        },
        error => {   
          console.log(error)   
        }
      )
    },         
  },
  created() { },
  async mounted() { 
    await ItemService.getItem(this.$route.params.id).then(
      response => { 
        this.item = response;
      },
      error => {   
        console.log(error)   
      }  
    )
    await LenseService.getLenses().then(
      response => { 
        this.lenses = response;
        this.lensesLoading = false;

      },
      error => {   
        console.log(error)   
      }      
    )
    await LenseService.getPillars().then(
      response => { 
        this.pillars = response;
        this.pillarsLoading = false;
      },
      error => {   
        console.log(error)   
      }      
    )
    await ChoiceService.getChoices().then(
      response => { 
        this.choices = response;
      },
      error => {   
        console.log(error)   
      }
    )
    this.processChoicesData()
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },


};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
